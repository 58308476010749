/* Timeline Css Start Here */
.timeline_detail {
    padding: 160px 0 60px;
}

.timeline_detail .heading_wrapper {
    text-align: center;
    margin-bottom: 40px;
}

.timeline_detail .heading_wrapper h4 {
    font-size: 18px;
    line-height: 20px;
}

.timeline_detail .heading_wrapper h3.year {
    font-size: 35px;
    line-height: 45px;
}

.timeline_detail .attachment_one {
    border: 1px solid #ddd;
    padding: 10px 10px;
}

.timeline_detail .attachment_one .img-box img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.timeline_detail .intro {
    padding: 40px 0 40px;
}

.timeline_detail .intro .title {
    text-align: center;
    padding-bottom: 10px;
}

.timeline_detail .intro .title h4 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
}

.timeline_detail .intro textarea {
    height: 150px;
    border-radius: 0;
    resize: none;
    border: 1px solid #eee;
    box-shadow: 0px 0px 5px #ccc;
    padding: 15px 15px;
}

.timeline_detail .pending .title {
    padding-bottom: 20px;
    text-align: center;
}

.timeline_detail .pending ul.pending-files {
    padding: 0;
    margin: 0;
    list-style: none;
}

.timeline_detail .pending ul {
    display: flex;
    flex-wrap: wrap;
}

.timeline_detail .pending ul li img {
    border-radius: 5px;
    box-shadow: 0px 0px 1px #ccc;
    width: 150px;
}

.timeline_detail .pending ul li {
    width: 13%;
    margin: 0 5px 15px;
    position: relative;
}

.timeline_detail .pending ul li span.delete {
    background: red;
    width: 19px;
    display: flex;
    align-items: center;
    height: 19px;
    justify-content: center;
    border-radius: 50px;
    font-size: 14px;
    color: #fff;
    position: absolute;
    left: -5px;
    top: -10px;
    cursor: pointer;
}

.timeline_detail .all-years {
    padding-top: 20px;
}

.timeline_detail .all-years ul.years {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.timeline_detail .all-years ul.years li {
    width: 33%;
    font-size: 16px;
    font-family: 'Rubik';
    margin: 0 0 11px;
}

.timeline_detail .all-years ul.years button {
    background: #000;
    border: 0;
    width: 50px;
    color: #fff;
}

.timeline_detail .all-years {
    padding-top: 20px;
}

.timeline_detail .all-years ul.years {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.timeline_detail .all-years ul.years li {
    width: 33%;
    font-size: 16px;
    font-family: 'Rubik';
    margin: 0 0 11px;
    background: #eeeeee94;
    padding: 10px 15px;
    border-radius: 3px;
}

.timeline_detail .all-years ul.years li a {
    justify-content: space-between;
    display: flex;
    color: #303030;
    text-decoration: none;
}

.timeline_detail .all-years ul.years button {
    background: #edfff8a1;
    border: 0;
    width: 50px;
    color: #000;
    font-size: 13px;
    border: 1px solid #eee;
}

.timeline_detail .all-years ul.years li span {
    display: block;
    width: 65px;
}

.timeline_detail .box {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.timeline_detail .box h4 {
    font-size: 20px;
    line-height: 30px;
}

.timeline_detail .box button {
    width: 100%;
    border: 0;
    padding: 12px 10px;
    background: #17723e;
    color: #fff;
    border-radius: 5px;
}

.timeline_detail .form-group {
    width: 31.7%;
    margin: 0px auto;
}

.timeline_detail .form-group .form-control {
    padding: 15px 15px;
    height: 50px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

/* Timeline Css End Here */

/* Timeline Detail Css Start Here */
.timeline-detail {
    padding: 60px 0;
}

.timeline-detail .timeline-box img {
    border-radius: 10px;
    filter: brightness(0.7);
    transition: 1s;
    height: 250px;
    object-fit: cover;
    width: 100%;
}

.timeline-detail .timeline-box:hover img {
    transform: scale(1.5);
}

.timeline-detail .timeline-box {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.timeline-detail .timeline-box .date {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15px;
    transition: 0.6s;
}

.timeline-detail .timeline-box:hover .date {
    transform: translateY(100px);
}

.timeline-detail .timeline-box .date h4 {
    font-size: 14px;
    color: #fff;
}

/* Timeline Detail Css End Here */