/* Universal Css Start Here */
/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Tillana:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&family=Ubuntu:wght@300;400;500;700&display=swap");
/* #familyTreeContainer>svg {
    background-color: #2E2E2E;
} */
/* Font Family */
:root {
    --first-color: #28b170;
    --second-color: #000;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Poppins", sans-serif;
}

@font-face {
    font-family: "manus";
    src: url("../fonts/manus.otf");
}

p {
    font-size: 16px;
}

h2,
h3,
h4 {
    font-family: "Rubik", sans-serif;
}

h5,
h6 {
    font-family: "Poppins", sans-serif;
}

h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
}

h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
}

h4 {
    font-size: 19px;
    line-height: 25px;
    font-weight: 400;
}

h5 {
    font-size: 13px;
    line-height: 23px;
}

figure {
    margin-bottom: 0;
}

.forBtn {
    background: #17713d;
    color: #fff;
    padding: 12px 20px;
    border-radius: 50px;
    transition: 0.6s;
    border: 2px solid #17713d;
    box-shadow: none;
    outline: none;
    text-decoration: none !important;
}

a.forBtn:hover {
    background: transparent;
    color: #17713d;
}

/* Universal Css End Here */

/* Inner Banner Css Start Here */
.inner_banner {
    padding: 150px 0 50px;
    background: #edfff8;
}

.inner_banner ul.breadcrumbs {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner_banner .title {
    text-align: center;
    margin-bottom: 15px;
}

.inner_banner .title h3 {
    color: #17723e;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 50px;
}

.inner_banner ul.breadcrumbs li a {
    position: relative;
    color: #000;
    text-decoration: none;
}

.inner_banner ul.breadcrumbs li a:after {
    content: "/";
    margin: 0 7px;
}

.inner_banner ul.breadcrumbs li {
    position: relative;
    color: #000;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
}

/* Inner Banner Css End Here */

/* Header Css Start Here */
.headerTop {
    padding: 20px 0;
}

#stickyHeader {
    position: absolute;
    top: 0;
    z-index: 9;
    width: 100%;
}

/* .headerTop.active {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9;
} */

.headerTop .logoDv {
    max-width: 130px;
}

.headerTop ul.menus {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

.headerTop ul.menus li {
    display: inline-block;
}

.headerTop ul.menus li a {
    font-size: 11px;
    font-family: "Rubik";
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 6px;
}

.headerTop .right-side {
    display: flex;
    align-items: center;
    justify-content: end;
}

.headerTop .right-side .search button {
    border: 0;
    background: transparent;
    padding: 0 10px 0 0px;
    box-shadow: none;
    outline: none;
}

.headerTop .right-side .search button i {
    font-size: 22px;
    color: #17723e;
}

.headerTop .right-side .button-group .head-btns {
    color: #17723e;
    padding: 10px 25px;
    margin: 0 10px;
    background: transparent;
    border: 1px solid #17723e;
    font-size: 14px;
    border-radius: 50px;
    text-decoration: none;
    transition: 0.6s;
}

.headerTop .right-side .button-group .head-btns:hover {
    background: #17723e;
    color: #fff;
    box-shadow: 0px 0px 8px #fff;
}

.headerTop .right-side .canvas-bar button {
    border: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    margin-top: 7px;
}

.headerTop .right-side .canvas-bar button span.---i {
    display: block;
    width: 25px;
    background: #000;
    height: 1px;
    margin: 6px 0;
}

.headerTop .right-side .canvas-bar button span.--i {
    display: block;
    width: 16px;
    background: #000;
    height: 1px;
    margin: 8px 0;
    margin: 0 0 0 auto;
}

.headerTop .right-side .canvas-bar {
    display: none;
}
@media (max-width: 1199px) {
    .headerTop ul.menus li a {
        font-size: 11px;
        padding: 0 6px;
    }
}
@media (max-width: 991px) {
    .favourite_quote_content {
        margin: 20px 0;
    }

    .headerTop ul.menus {
        display: none;
    }

    .headerTop .button-group {
        display: none;
    }

    .headerTop .right-side .canvas-bar {
        display: block;
    }

    .headerTop .row {
        flex-wrap: unset;
    }
}

/* Header Css End Here */

/* Search Modal Css Start Here */
#SearchModal .modal-body {
    border-radius: 0;
    padding: 0;
}

#SearchModal .modal-content {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

#SearchModal .modal-body .form-group {
    display: flex;
    position: relative;
}

#SearchModal .modal-body .form-group .form-control {
    border-radius: 0;
    height: 50px;
    padding: 0 20px;
}

#SearchModal .modal-body .form-group button {
    position: absolute;
    right: 0;
    height: 100%;
    border: 0;
    box-shadow: none;
    background: transparent;
    outline: none;
    padding: 0 15px;
    font-size: 20px;
    color: #0c391f;
}

/* Search Modal Css End Here */

/* Canvas Css Start Here */
.canvas_header {
    background: #094623;
    position: fixed;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: translateX(150%);
    right: 0;
    transition: 1s;
    z-index: 99;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.canvas_header.active {
    transform: translateX(0%);
}

.canvas_header button.for-close {
    position: absolute;
    top: 20px;
    border: 0;
    right: 30px;
    background: transparent;
    padding: 0;
    outline: none;
    box-shadow: none;
}

.canvas_header ul.menus {
    margin: 0;
    list-style: none;
    padding: 0;
}

.canvas_header ul.menus li.menu-items {
    padding: 5px 0;
}

.canvas_header ul.menus li.menu-items a {
    font-size: 25px;
    color: #fff;
    font-family: "Poppins";
    font-weight: 500;
    text-transform: uppercase;
    line-height: 35px;
    text-decoration: none;
    position: relative;
}

.canvas_header ul.menus li.menu-items a:before {
    content: "";
    background: #fff;
    width: 0%;
    height: 3px;
    position: absolute;
    bottom: -5px;
    transition: 0.8s;
}

.canvas_header ul.menus li.menu-items a:hover:before {
    width: 100%;
}

@media (max-width: 575px) {
    .canvas_header ul.menus li.menu-items a {
        font-size: 20px;
        line-height: 35px;
    }
}

/* Canvas Css End Here */

/* Banner Css Start Here */
.banner_sec {
    padding: 60px 0 120px;
    position: relative;
}

.banner_sec .content-wrapper h2 {
    padding-bottom: 10px;
    color: #000;
    font-size: 35px;
    line-height: 45px;
}

span.colored {
    color: #17723e;
}

.banner_sec .content-wrapper p {
    color: #838181;
    font-size: 16px;
    line-height: 26px;
}

.banner_sec .content-wrapper p a {
    text-decoration: none;
    color: #17723e;
}

.banner_sec .dots-img {
    position: absolute;
    bottom: -60px;
    left: 0;
    z-index: -1;
    animation: rotation 6s infinite linear;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

.banner_sec .img-box {
    text-align: end;
    pointer-events: none;
}

.banner_sec .img-box img.img-fluid {
    background: #e8f2ec;
    padding: 25px 25px;
    border-radius: 10px;
}

.banner_sec .elements {
    position: absolute;
    pointer-events: none;
}

.banner_sec .element1 {
    top: 40px;
    left: 20px;
}

.banner_sec .element2 {
    top: 47%;
    left: 6%;
}

.banner_sec .element3 {
    bottom: 5%;
    left: 35%;
}

.banner_sec .element4 {
    top: 6%;
    left: 45%;
}

.banner_sec .element5 {
    bottom: 0;
    right: 3%;
}

.banner_sec .element6 {
    top: 30%;
    right: 7%;
}

@keyframes popup {
    0% {
        -webkit-transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.8);
    }

    80% {
        -webkit-transform: scale(0.7);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

.banner_sec .gift {
    padding-top: 35px;
    text-align: center;
}

/* .banner_sec .gift h3 {
    font-family: 'manus';
    font-size: 62px;
    line-height: 65px;
    color: #FF0000;
    font-weight: 500;
    transform: rotate(3deg);
} */
.banner_sec .gift h3 {
    font-family: "Rubik";
    font-size: 55px;
    line-height: 65px;
    color: #ff0000;
    font-weight: 400;
}

@media (max-width: 1300px) {
    .banner_sec .elements {
        display: none;
    }
}

@media (max-width: 1250px) {
    .banner_sec .element2 {
        top: 62%;
    }
}

@media (max-width: 991px) {
    .banner_sec {
        padding: 60px 0 70px;
    }

    .banner_sec .elements {
        display: none;
    }
}

@media (max-width: 575px) {
    .banner_sec {
        padding: 30px 0 30px;
    }

    .banner_sec .content-wrapper h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .banner_sec .gift h3 {
        font-size: 35px;
        line-height: 45px;
    }

    .banner_sec .content-wrapper p {
        font-size: 15px;
        line-height: 23px;
    }
}

/* Banner Css End Here */

/* Care Sec Css Start Here */
.custom-shape-divider-top-care {
    margin-bottom: -155px;
    fill: #fff;
    z-index: -9;
}

.care_sec {
    padding: 300px 0px 150px;
    background: #edf9f2;
}

.care_sec .picture_box img {
    filter: grayscale(1);
    transition: 0.6s;
}

.care_sec .picture_box:hover img {
    filter: unset;
}

.care_sec .comment_box {
    display: flex;
    align-items: center;
}

.care_sec .comment_box1 {
    background-image: url(../img/thinkbox1.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 70%;
    padding: 30px 30px 60px;
    position: absolute;
    z-index: 1;
    top: -120px;
    left: 40px;
    animation: mover 1s infinite alternate;
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

.care_sec .comment_box2 {
    background-image: url(../img/thinkbox2.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 65%;
    padding: 70px 30px 40px;
    position: absolute;
    z-index: 1;
    bottom: -70px;
    right: 40px;
    animation: mover2 1s infinite alternate;
}

@keyframes mover2 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(10px);
    }
}

.care_sec .comment_box p {
    margin: 0;
    color: #222a41;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.care_sec .comment_box .imgBox img {
    width: 50px;
    border-radius: 50px;
    border: 1px solid #c3c3c3;
    height: 50px;
    box-shadow: 0px 0px 11px #ccc;
    margin-right: 20px;
}

.care_sec .content-wrapper h2 {
    padding-bottom: 10px;
}

.care_sec .content-wrapper .button-group {
    margin-top: 35px;
}

@media (max-width: 1300px) {
    .custom-shape-divider-top-care {
        margin-bottom: -125px;
    }
}

@media (max-width: 1250px) {
    .custom-shape-divider-top-care {
        margin-bottom: -124px;
    }
}

@media (max-width: 1100px) {
    .custom-shape-divider-top-care {
        margin-bottom: -108px;
    }

    .care_sec .content-wrapper h2 {
        padding-bottom: 10px;
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 991px) {
    .care_sec {
        padding: 240px 0px 80px;
    }

    .custom-shape-divider-top-care {
        margin-bottom: -94px;
    }

    .care_sec .comment_box2 {
        bottom: -30px;
    }

    .care_sec .content-wrapper {
        margin-top: 60px;
    }
}

@media (max-width: 575px) {
    .custom-shape-divider-top-care {
        display: none;
    }

    .care_sec {
        padding: 150px 0px 80px;
    }

    .care_sec .comment_box1 {
        width: 100%;
        left: 0;
    }

    .care_sec .comment_box2 {
        width: 100%;
        right: 0;
        bottom: -130px;
    }

    .care_sec .content-wrapper {
        margin-top: 160px;
    }

    .care_sec .content-wrapper h2 {
        font-size: 23px;
        line-height: 35px;
    }

    .care_sec .content-wrapper p {
        font-size: 14px;
    }
}

/* Care Sec Css End Here */

/* Family Tree Sec Css Start Here */
.family_tree {
    padding: 80px 0;
}

.family_tree .content-wrapper {
    padding-bottom: 20px;
}

.family_tree .content-wrapper p {
    font-size: 14px;
}

.family_tree .content-wrapper .button-group {
    margin-top: 35px;
}

.family_tree .content-wrapper h2 {
    margin-bottom: 15px;
}

.family_tree .content-wrapper .boxes {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.family_tree .content-wrapper .boxes .icon-box {
    background: #17713d;
    width: 140px;
    margin: 0 5px;
    padding: 15px 0;
    border-radius: 7px;
    transition: 0.6s;
    border: 2px solid #17713d;
}

/* .family_tree .content-wrapper .boxes .icon-box:hover {
    background: #edf9f2;
} */

.family_tree .content-wrapper .boxes .icon-box img {
    filter: brightness(0) invert(1);
    width: 40px;
    transition: 0.6s;
}

/* .family_tree .content-wrapper .boxes .icon-box:hover img {
    filter: unset;
} */

.family_tree .content-wrapper .boxes .icon-box h4 {
    margin: 10px 0 0 0;
    color: #fff;
    font-size: 16px;
    transition: 0.6s;
}

/* .family_tree .content-wrapper .boxes .icon-box:hover h4 {
    color: #17713d;
} */

.family_tree .tree-img {
    position: relative;
    overflow: hidden;
}

.family_tree .tree-img:before {
    content: "";
    background: #17713d45;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 1.2s;
    transform: scale(0);
    border-radius: 50%;
    left: 0;
    right: 0;
}

.family_tree .tree-img:hover:before {
    transform: scale(1);
    border-radius: 0;
}

@media (max-width: 1100px) {
    .family_tree .content-wrapper h2 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width: 991px) {
    .family_tree {
        padding: 60px 0;
    }

    .family_tree .content-wrapper {
        padding-bottom: 50px;
    }
}

@media (max-width: 575px) {
    .family_tree {
        padding: 40px 0;
    }

    .family_tree .content-wrapper .boxes {
        display: block;
    }

    .family_tree .content-wrapper .boxes .icon-box {
        margin: 0px auto;
        margin-bottom: 10px;
        width: 100%;
    }
}

/* Family Ree Sec Css End Here */

/* Story Sec Css Start Here */
.first_story {
    padding: 80px 0;
    background: #edf9f2;
}

.first_story .heading-wrapper {
    padding-bottom: 30px;
}

.first_story .heading-wrapper h2.title {
    padding-bottom: 5px;
}

.first_story .heading-wrapper .button-group {
    margin-top: 30px;
}

.custom-shape-divider-bottom-story path {
    fill: #edf9f2;
}

@media (max-width: 1100px) {
    .first_story .heading-wrapper h2.title {
        padding-bottom: 5px;
        font-size: 32px;
        line-height: 42px;
    }
}

@media (max-width: 991px) {
    .first_story {
        padding: 60px 0;
    }
}

@media (max-width: 575px) {
    .first_story {
        padding: 40px 0;
    }

    .first_story .heading-wrapper h2.title {
        font-size: 28px;
        line-height: 38px;
    }
}

/* Story Sec Css End Here */

/* Digital Storage Css Start Here */
.digital_storage {
    padding: 60px 0 100px;
}

.digital_storage .img-box {
    position: relative;
    overflow: hidden;
}

.digital_storage .img-box:before {
    content: "";
    background: #17713d45;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 1.2s;
    transform: scale(0);
    border-radius: 50%;
    left: 0;
    right: 0;
}

.digital_storage .img-box:hover:before {
    transform: scale(1);
    border-radius: 0;
}

.digital_storage .img-box img {
    height: 560px;
    width: 100%;
    object-fit: cover;
    object-position: -220px center;
}

.digital_storage .content-wrapper p {
    font-size: 14px;
    line-height: 19px;
}

.digital_storage .content-wrapper p a {
    text-decoration: none;
}

.digital_storage .content-wrapper .button-group {
    margin-top: 40px;
}

@media (max-width: 1100px) {
    .digital_storage .content-wrapper h2 {
        font-size: 32px;
        line-height: 42px;
    }
}

@media (max-width: 991px) {
    .digital_storage .img-box img {
        height: auto;
        width: 100%;
        object-position: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .digital_storage {
        padding: 40px 0 60px;
    }

    .digital_storage .content-wrapper h2 {
        font-size: 25px;
        line-height: 35px;
    }
}

/* Digital Storage Css End Here */

/* Newsletter Css Start Here */
.newsletter_sec {
    background: #edfff8;
    padding: 40px 0;
}

.newsletter_sec h2 {
    color: #000;
}

.newsletter_sec .form-group {
    display: flex;
}

.newsletter_sec .form-group .form-control {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 50px;
    height: 60px;
    padding: 5px 30px;
    box-shadow: none;
}

.newsletter_sec .form-group button.forBtn {
    width: 150px;
    position: absolute;
    right: 21px;
    padding: 10px 10px;
    margin-top: 6px;
}

.newsletter_sec .form-group button.forBtn:hover {
    background: transparent;
    color: #17713d;
}

@media (max-width: 991px) {
    .newsletter_sec .heading-wrapper h3 {
        font-size: 27px;
    }
}

@media (max-width: 575px) {
    .newsletter_sec .heading-wrapper h3 {
        font-size: 21px;
        line-height: 31px;
    }

    .newsletter_sec .form-group {
        display: block;
        margin-bottom: 0;
    }

    .newsletter_sec .form-group button.forBtn {
        width: 100%;
        position: unset;
    }
}

/* Newsletter Css End Here */

/* Footer Css Start Here */
footer {
    padding: 85px 0 45px;
    background: #edfff8;
    border-top: 1px solid #ddd;
}

.copyrights {
    background: #17713d;
    padding: 20px 0;
}

.copyrights p {
    margin: 0;
    text-align: center;
    color: #fff;
}

footer .footer-logo {
    margin-top: -40px;
    margin-bottom: 20px;
}

footer .footer-logo img {
    max-width: 140px;
}

footer .footer-content p {
    font-size: 14px;
}

footer .footer-content .title h4 {
    color: #000;
    font-weight: 500;
    font-family: "Rubik";
    margin-bottom: 20px;
}

footer .footer-content ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

footer .footer-content ul li {
    margin: 9px 0;
}

footer .footer-content ul li a {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

footer ul.social-media li {
    display: inline-block;
    margin-right: 13px;
}

footer ul.social-media li a {
    color: #17713d;
    font-size: 19px;
}

footer .footer-content .button-group {
    display: flex;
    margin-top: 15px;
}

@media (max-width: 991px) {
    footer .footer-content {
        padding: 0 0 30px;
    }

    footer .footer-content .button-group a img {
        width: 130px;
    }
}

@media (max-width: 575px) {
    footer {
        padding: 85px 0 25px;
    }
}

/* Footer Css End Here */

/* Loader Css Start Here */
#introduction {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 105;
    background-color: #000;
    cursor: pointer;
    overflow: hidden;
    top: 0;
    left: 0;
}

#introduction .image-container {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#introduction .image-container h2 {
    color: #fff;
    font-family: "Rubik";
    text-transform: uppercase;
}

@media (max-width: 768px) {
    #introduction .image-container {
        height: 100vh;
    }
}

#introduction .banner-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1600px;
}

#introduction .slide {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: #fff;
}

#introduction .slide-2 {
    height: 0;
}

.slide-1 {
    z-index: 999;
}

.image-container {
    background: #00401c;
    -webkit-backdrop-filter: blur(28px);
    backdrop-filter: blur(28px);
}

#introduction .image-container img {
    filter: brightness(0) invert(1);
}

#content {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 600;
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Loader Css End Here */
